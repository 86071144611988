import React from 'react'

import {PostHeader} from './postHeader'
import Preview from './images/preview.png'

import './posts.scss'

const Post = ({post}) => (
    <div className="agnoris-blog-home-post">
        <img className="agnoris-blog-home-post-image" src={Preview} alt="Main article preview" />
        <div className="agnoris-blog-home-post-content">
            <PostHeader {...{post}}/>
            <div className="agnoris-blog-home-post-article">
                <div className="agnoris-blog-home-post-title">{post.frontmatter.title}</div>
                <div className="agnoris-blog-home-post-lede">{post.excerpt}</div>
                <a className="agnoris-blog-home-post-readmore" href={post.frontmatter.path}>Read More</a>
            </div>
        </div>
    </div>
)

export default Post;