import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import {BigPost, Post} from "../components/posts"
import SEO from "../components/seo"

const IndexPage = (
  {
    data: {
      allMarkdownRemark: { edges },
    },
  }
) => {
  const Posts = edges
    .filter((edge, index) => !!edge.node.frontmatter.date && index > 0)
    .map(edge => <Post key={edge.node.id} post={edge.node} />)
  
  const mainPost = edges[0].node
  
  return (
  <Layout>
    <SEO title="Home"/>
    <BigPost post={mainPost} />
    <div>{Posts}</div>
  </Layout>
  )
}


export default IndexPage


export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 2500)
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            path
            title
            author {
              id
              bio
              name
              avatar {
                children {
                  ... on ImageSharp {
                    fixed(width: 30, height: 30, quality: 75, grayscale: false) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }              
            }
          }
        }
      }
    }
  }
`