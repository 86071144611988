import React from 'react'

import {AgnorisButton} from '../button'
import {PostHeader} from './postHeader'
import Placeholder from './images/placeholder.jpg'

import './posts.scss'

const BigPost = ({post}) => {

    function goToPost() {
        window.location.href = post.frontmatter.path;
    }

    return(
        <div className="agnoris-blog-home-first-post">
            <PostHeader {...{post}}/>
    
            <div className="agnoris-blog-home-first-post-content">
                <div className="agnoris-blog-home-first-post-article">
                    <div className="agnoris-blog-home-first-post-title">{post.frontmatter.title}</div>
                    <div className="agnoris-blog-home-first-post-lede">{post.excerpt}</div>
                    <AgnorisButton text="Read more" styleType="hollo" onClick={goToPost}/>
                </div>
                <img className="agnoris-blog-home-first-post-image" src={Placeholder} alt="Main article preview" />
            </div>
        </div>
    )
}

export default BigPost;